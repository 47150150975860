import { initializeApp } from 'firebase/app';
import {
  getAuth,
  signInWithEmailAndPassword,
  signOut,
  UserCredential,
  User,
  UserInfo,
  IdTokenResult,
  onAuthStateChanged,
  sendPasswordResetEmail,
  Unsubscribe,
  setPersistence,
  browserLocalPersistence,
} from 'firebase/auth';
import _ from 'lodash';
import { APIErrorClient, apiUrl, localConfig } from '.';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_GCP_API_KEY,
  authDomain: process.env.REACT_APP_API_GCP_AUTH_DOMAIN,
};

export const firebaseApp = initializeApp(firebaseConfig);
export const auth = getAuth(firebaseApp);
auth.tenantId = localConfig.TENANT ?? '';
console.info('Firebase tenant: ', { apiUrl }, process.env, localConfig);

export type FirebaseUser = Pick<User, 'uid' | 'email' | 'emailVerified'> & {
  idToken: string;
  jwtToken: string;
  expirationTime: string;
};

const user2FirebaseUser = async (user: User): Promise<FirebaseUser> => {
  console.info('user2FirebaseUser', { user });
  user?.providerData?.map((userInfo: UserInfo) => console.info({ userInfo }));
  const idToken = await user.getIdToken(); // args = forceRefresh: boolean
  const idTokenResult: IdTokenResult = await user.getIdTokenResult();
  const jwtToken = idTokenResult.token;
  const expirationTime = idTokenResult.expirationTime;
  const firebaseUser: FirebaseUser = {
    ..._.pick(user, ['uid', 'email', 'emailVerified']),
    idToken,
    jwtToken,
    expirationTime,
  };
  return firebaseUser;
};

export const firebaseSignOut = async () => {
  const { currentUser } = auth;
  if (currentUser) {
    console.info('firebaseSignOut: currentUser', currentUser);
    await signOut(auth);
  } else {
    console.warn('firebaseSignOut: no currentUser');
  }
  unsub();
};

let unsub: Unsubscribe;

// Firebase constants for APIErrorClient
export const NO_ID_TOKEN = 'NO_ID_TOKEN';
export const MFA_REQUIRED = 'MFA_REQUIRED';
export const WRONG_PASSWORD = 'WRONG_PASSWORD';
export const EMAIL_NOT_VERIFIED = 'EMAIL_NOT_VERIFIED';
export const RELOGIN_REQUIRED = 'RELOGIN_REQUIRED';

class ClientError extends Error implements APIErrorClient {
  errorKey: APIErrorClient['errorKey'];
  statusCode?: APIErrorClient['statusCode'];
  submittedData: any;
  desc?: string;
  originalError?: any;
  constructor(errorKey: APIErrorClient['errorKey']) {
    super(errorKey);
    this.errorKey = errorKey;
    this.submittedData = null;
  }
}
export class MFAError extends ClientError {
  constructor() {
    super(MFA_REQUIRED);
  }
}
export class PasswordError extends ClientError {
  constructor(desc?: string) {
    super(WRONG_PASSWORD);
    this.desc = desc;
  }
}
export class ReloginRequired extends ClientError {
  constructor(desc?: string) {
    super(RELOGIN_REQUIRED);
    this.desc = desc;
  }
}
export class EmailValidationRequired extends ClientError {
  constructor(desc?: string) {
    super(EMAIL_NOT_VERIFIED);
    this.desc = desc;
  }
}

export const firebaseSignIn = async (
  onTokenRefresh: (idToken: string) => void,
  email?: string,
  password?: string
) => {
  let user: User;
  await setPersistence(auth, browserLocalPersistence);
  console.info('firebaseSignIn: setPersistence OK');
  if (!email || !password) {
    console.info('firebaseSignIn: no email/password from startup');
    const { currentUser } = auth;
    if (currentUser) {
      console.info('firebaseSignIn: currentUser', currentUser);
      user = currentUser;
    } else {
      console.warn('firebaseSignIn: no currentUser');
      return null;
    }
  } else {
    console.info('firebaseSignIn: email/password from login');

    try {
      console.debug('tyring signInWithEmailAndPassword', { email, password });
      const userCredential: UserCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      user = userCredential.user;
      console.info('signInWithEmailAndPassword', { user, userCredential });
    } catch (e: any) {
      console.warn('signInWithEmailAndPassword error', e);
      if (e?.code === 'auth/multi-factor-auth-required') {
        console.info('The user is a multi-factor user. Second factor challenge is required.');
        throw new MFAError();
      } else if (e?.code === 'auth/wrong-password') {
        console.warn('wrong password');
        throw new PasswordError('Wrong password');
      } else {
        throw e;
      }
    }
  }
  console.info('firebaseSignIn; user authenticated - handlePostSignIn', { user });
  return handlePostSignIn(user, onTokenRefresh);
};

const handlePostSignIn = async (user: User, onTokenRefresh: (idToken: string) => void) => {
  console.info('firebaseSignIn; user authenticated', { user });

  const firebaseUser: FirebaseUser = await user2FirebaseUser(user);
  console.info({ firebaseUser });
  onTokenRefresh(firebaseUser.idToken); // setup the first

  if (unsub) {
    unsub();
  }
  unsub = onAuthStateChanged(auth, async (newUser) => {
    if (newUser) {
      console.info('onAuthStateChanged - signed In', { newUser });
      const newFirebaseUser: FirebaseUser = await user2FirebaseUser(newUser);
      console.info('onAuthStateChanged', { newFirebaseUser });
      if (newFirebaseUser.idToken !== firebaseUser.idToken) {
        console.info('onAuthStateChanged: new token refreshed', { newFirebaseUser });
        onTokenRefresh(newFirebaseUser.idToken);
      }
    } else {
      console.info('onAuthStateChanged - signed Out', { newUser });
    }
  });
  return firebaseUser;
};

// FIXME Backend will not change the stored psw in db
// https://support.google.com/firebase/answer/7000714
export const firebaseForgotPassword = async (email: string) =>
  await sendPasswordResetEmail(auth, email);
