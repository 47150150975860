import { configureStore } from '@reduxjs/toolkit';
import { signinSlice } from '../redux/signinSlice';
import { voucherSlice } from '../redux/voucherSlice';
import { signupSlice } from '../redux/signupSlice';
import { appSlice } from '../redux/appSlice';
import { userProfileSlice } from '../redux/profileSlice';
// ...

export const store = configureStore({
  reducer: {
    app: appSlice.reducer,
    auth: signinSlice.reducer,
    voucher: voucherSlice.reducer,
    signup: signupSlice.reducer,
    profile: userProfileSlice.reducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
