import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { UserProfile, UserProfileDTOIn, UserProfileDTOOut } from '../types';
import {
  APIErrorClient,
  authApi,
  handleNetworkError,
  initialRequestStatus,
  RequestStatus,
  UNKNOWN_ERROR_KEY,
} from '../../api';
import _ from 'lodash';
import { RootState } from '../../stores/store';

export const fetchProfileAsync = createAsyncThunk<
  UserProfileDTOIn, // output
  number, // input
  { rejectValue: APIErrorClient } // error output
>('profile/fetch', async (userId: number, thunkAPI) => {
  try {
    const response = await authApi.get(`authorization`); // ALT GET '/profile'
    return response.data as UserProfileDTOIn;
  } catch (e) {
    return thunkAPI.rejectWithValue(handleNetworkError('loginAsync', e, userId) as APIErrorClient);
  }
});

export const updateProfileAsync = createAsyncThunk<
  UserProfileDTOIn, // output
  UserProfileDTOOut, // input
  { rejectValue: APIErrorClient } // error output
>('profile/update', async (userData: UserProfileDTOOut, thunkAPI) => {
  try {
    const response = await authApi.put(`profile`, userData);
    const { data } = response;

    if ((data?.email && userData.type === 'EMAIL') || userData.type === 'PASSWORD') {
      // TODO - re-update Firebase backend!
      const email = data?.email ?? (thunkAPI.getState() as RootState).auth.email;
      const password = userData.newPassword;
      console.warn('updateProfileAsync: re-login with firebase required');
      // thunkAPI.dispatch(loginAsync({ email, password }));
    }

    return data as UserProfileDTOIn;
  } catch (e) {
    return thunkAPI.rejectWithValue(
      handleNetworkError('updateProfileAsync', e, _.omit(userData, 'type')) as APIErrorClient
    );
  }
});

interface ProfileState extends RequestStatus, Partial<UserProfile> {}

const initialState: ProfileState = {
  ...initialRequestStatus,
};

export const userProfileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {},
  extraReducers: (bd) => {
    bd.addCase(fetchProfileAsync.pending, (state, action) => {
      state.loading = true;
    });
    bd.addCase(fetchProfileAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.phone2fa = action.payload.phone2fa;
      state.email = action.payload.email;
    });
    bd.addCase(fetchProfileAsync.rejected, (state, action) => {
      state.loading = false;
    });
    // UPDATE
    bd.addCase(updateProfileAsync.pending, (state, action) => {
      state.loading = true;
    });
    bd.addCase(updateProfileAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.serverError = undefined; // TO not forget
      // check every fields that might have server changed
      if (action.payload?.firstName) {
        state.firstName = action.payload.firstName;
      }
      if (action.payload?.lastName) {
        state.lastName = action.payload.lastName;
      }
      if (action.payload?.phone2fa) {
        state.phone2fa = action.payload.phone2fa;
      }
      if (action.payload?.email) {
        state.email = action.payload.email;
      }
    });
    bd.addCase(updateProfileAsync.rejected, (state, action) => {
      state.loading = false;
      if (action.payload) {
        state.serverError = action.payload;
      } else {
        state.serverError = {
          errorKey: UNKNOWN_ERROR_KEY,
          submittedData: {}, // FIXME
        };
      }
    });
  },
});
