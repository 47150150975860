// Password reset API
import {
  anonApi,
  CUSTOM_CONFLICT_STATUS,
  FORBIDDEN_STATUS,
  handleNetworkError,
  UNAUTHORIZED_STATUS,
} from './index';

export const apiForgotPsw = async ({ email }: { email: string }) => {
  try {
    return await anonApi.post('reset-password/', { email });
  } catch (e) {
    throw handleNetworkError('apiForgotPsw', e, { email }, [], true);
  }
};

// return session token
export const apiStartResetPsw = async ({ token, email }: { token: string; email: string }) => {
  try {
    const response = await anonApi.post(`reset-password/start`, { token, email });
    return response?.data?.sessionToken;
  } catch (e) {
    throw handleNetworkError('apiStartResetPsw', e, { token }, [
      UNAUTHORIZED_STATUS, // Invalid
      FORBIDDEN_STATUS, // Expire
    ]);
  }
};

export const apiApplyResetPsw = async ({
  sessionToken,
  password,
  email,
}: {
  sessionToken: string;
  password: string;
  email: string;
}) => {
  try {
    return await anonApi.post(`reset-password/apply`, {
      sessionToken,
      password,
      email,
    });
  } catch (e) {
    throw handleNetworkError('apiApplyResetPsw', e, { sessionToken, password }, [
      UNAUTHORIZED_STATUS, // Invalid
      FORBIDDEN_STATUS, // Expired
      CUSTOM_CONFLICT_STATUS, // Bad format
    ]);
  }
};
