import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Nav, Row, Spinner, Toast } from 'react-bootstrap';
import { ChevronRight } from 'react-feather';
import { useTranslation } from '../../hooks/useTranslation';
import { TKeys } from '../../i18n/en';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxTKHooks';
import { fetchProfileAsync, updateProfileAsync } from '../../redux/profileSlice';
import { SubsType, UpdateProfileType, UserProfileDTOOut } from '../../redux/types';
import { unwrapResult } from '@reduxjs/toolkit';
import _ from 'lodash';
import { EMAIL_REGEX, ONE_DIGIT, ONE_LOWERCASE, ONE_UPPERCASE } from '../../utils/validation';
import { routes } from '../routes';
import { Link } from 'react-router-dom';

interface Props {}

enum NavEntry {
  FIRSTNAME = 'firstname_label',
  LASTNAME = 'lastname_label',
  // PHONE = 'phone2fa_label', // FIXME for later intg
  PASSWORD = 'psw_label',
  EMAIL = 'email_label',
}

const navEntries = Object.values(NavEntry);

// eslint-disable-next-line no-empty-pattern
export const Profile = ({}: Props) => {
  const t = useTranslation();
  const [currentNav, setCurrentNav] = useState<NavEntry | null>(null);
  const handleSelect = (eventKey: string | null) => {
    if (eventKey) {
      // reset form
      setSubmitOnce(false);
      setProfile({} as any);
      setCurrentNav(eventKey as NavEntry);
    }
  };
  const dispatch = useAppDispatch();
  const authState = useAppSelector((state) => state.auth);
  const profileState = useAppSelector((state) => state.profile);

  // fetch at mount
  useEffect(() => {
    authState.userId && dispatch(fetchProfileAsync(authState.userId));
    // .then(unwrapResult)
    // .then((userDTOIn) => setProfile(userDTOIn));
  }, [authState.userId]);

  // state
  const [profile, setProfile] = useState<UserProfileDTOOut & { repeatPassword?: string }>(
    {} as any
  );
  // server validation
  const [submitOnce, setSubmitOnce] = useState<boolean>(false);
  const [toastContent, setToastContent] = useState<string | null>(null);

  const subscription = useAppSelector((state) => state.auth.subscription);

  const passwordProtectedField = [NavEntry.EMAIL, NavEntry.PASSWORD].includes(currentNav as any);

  // FIXME better validation
  const invalidUsername =
    // @ts-ignore
    _.isEmpty(profile.email) || !EMAIL_REGEX.test(profile.email);
  const invalidPassword =
    profile.repeatPassword !== profile.newPassword ||
    _.isEmpty(profile.newPassword) ||
    // @ts-ignore
    profile.newPassword.length < 6 ||
    // @ts-ignore
    profile.newPassword.length > 32 ||
    // @ts-ignore
    !ONE_DIGIT.test(profile.newPassword) ||
    // @ts-ignore
    !ONE_LOWERCASE.test(profile.newPassword) ||
    // @ts-ignore
    !ONE_UPPERCASE.test(profile.newPassword);
  const invalidFirstName = _.isEmpty(profile.firstName);
  const invalidLastName = _.isEmpty(profile.lastName);
  const invalidCurrentPassword = passwordProtectedField && _.isEmpty(profile.password);

  // server validation - updatedUserData is the delta
  const updatedUserData = _.omit(profile, 'repeatPassword');
  const displayServerError =
    !!profileState.serverError &&
    _.isEqual(updatedUserData, profileState.serverError.submittedData);

  const onSubmit = (e: any, validationRequired: boolean, type: UpdateProfileType) => {
    e.preventDefault();
    e.stopPropagation();

    if (validationRequired) {
      setSubmitOnce(true);
    } else {
      setSubmitOnce(false);
      dispatch(updateProfileAsync({ ...updatedUserData, type }))
        .then(unwrapResult)
        .then((success) => {
          console.info({ success });
          // reset state - FIXME no resetting them all ?
          setProfile({} as any);
          // FIXME reset passwords is necessary ...
          setProfile({
            repeatPassword: '',
            password: '',
            newPassword: '',
          } as any);
          // setCurrentNav(null); // FIXME useful to reset ?
          setToastContent('profile_updated' as TKeys);
        })
        .catch((rejectedValueOrSerializedError) => {});
    }
  };

  const renderedPassword = (
    <>
      <Form.Group controlId="formBasicPassword">
        <Form.Label>{t('psw_label')}</Form.Label>
        <Form.Control
          type="password"
          placeholder={t('psw_pl')}
          value={profile.newPassword}
          onChange={(e) => {
            const newValue = e.currentTarget.value;
            setProfile((p) => ({ ...p, newPassword: newValue }));
          }}
        />
      </Form.Group>
      <Form.Group controlId="formBasicPassword">
        <Form.Label>{t('psw_repeat_label')}</Form.Label>
        <Form.Control
          type="password"
          isInvalid={submitOnce && invalidPassword}
          placeholder={t('psw_pl')}
          value={profile.repeatPassword}
          onChange={(e) => {
            const newValue = e.currentTarget.value;
            setProfile((p) => ({ ...p, repeatPassword: newValue }));
          }}
        />
        <Form.Control.Feedback type="invalid" style={{ color: 'red' }}>
          {t('val_psw_strength')}
        </Form.Control.Feedback>
      </Form.Group>
      {currentNav && (
        <Button
          variant="primary"
          type="submit"
          disabled={(invalidPassword && submitOnce) || !profile.newPassword}
          onClick={(e) => onSubmit(e, invalidPassword || invalidCurrentPassword, 'PASSWORD')}
        >
          {t('save_btn')}
        </Button>
      )}
    </>
  );

  const renderedFirstname = (
    <>
      <Form.Group controlId="formFirstname">
        <Form.Label>{t('firstname_label')}</Form.Label>
        <Form.Control
          isInvalid={submitOnce && invalidFirstName}
          placeholder={t('firstname_pl')}
          value={profile.firstName || profileState.firstName}
          onChange={(e) => {
            const newValue = e.currentTarget.value;
            setProfile((p) => ({ ...p, firstName: newValue }));
          }}
        />
        <Form.Control.Feedback type="invalid" style={{ color: 'red' }}>
          {t('val_non_empty')}
        </Form.Control.Feedback>
      </Form.Group>
      {currentNav && (
        <Button
          variant="primary"
          type="submit"
          disabled={(invalidFirstName && submitOnce) || !profile.firstName}
          onClick={(e) => onSubmit(e, invalidFirstName, 'DETAIL')}
        >
          {t('save_btn')}
        </Button>
      )}
    </>
  );
  const renderedLastname = (
    <>
      <Form.Group controlId="formLastname">
        <Form.Label>{t('lastname_label')}</Form.Label>
        <Form.Control
          placeholder={t('lastname_pl')}
          isInvalid={submitOnce && invalidLastName}
          value={profile.lastName || profileState.lastName}
          onChange={(e) => {
            const newValue = e.currentTarget.value;
            setProfile((p) => ({ ...p, lastName: newValue }));
          }}
        />
      </Form.Group>
      {currentNav && (
        <Button
          variant="primary"
          type="submit"
          disabled={(invalidLastName && submitOnce) || !profile.lastName}
          onClick={(e) => onSubmit(e, invalidLastName, 'DETAIL')}
        >
          {t('save_btn')}
        </Button>
      )}
    </>
  );
  const renderedPhone2FA = (
    <>
      <Form.Group controlId="formPhone2fa">
        <Form.Label>{t('phone2fa_label')}</Form.Label>
        <Form.Control
          placeholder={t('phone2fa_pl')}
          isInvalid={false}
          type="tel"
          value={profile.newPhone2fa ?? profileState.phone2fa}
          onChange={(e) => {
            const newValue = e.currentTarget.value;
            setProfile((p) => ({ ...p, newPhone2fa: newValue }));
          }}
        />
      </Form.Group>
      {currentNav && (
        <Button
          variant="primary"
          type="submit"
          disabled={!profile?.newPhone2fa}
          onClick={(e) => onSubmit(e, false, 'PHONE_2FA')}
        >
          {t('save_btn')}
        </Button>
      )}
    </>
  );
  const renderedEmail = (
    <>
      <Form.Group controlId="formEmail">
        <Form.Label>{t('email_label')}</Form.Label>
        <Form.Control
          isInvalid={submitOnce && invalidUsername}
          placeholder={t('email_pl')}
          value={profile.email || profileState.email}
          onChange={(e) => {
            const newValue = e.currentTarget.value;
            setProfile((p) => ({ ...p, email: newValue }));
          }}
        />
        <Form.Control.Feedback type="invalid" style={{ color: 'red' }}>
          {t('val_misc_format')}
        </Form.Control.Feedback>
        {!authState.emailConfirmed && (
          <Form.Text style={{ color: 'orange' }}>
            {t('email_to_confirm' as TKeys)}
            <br />
            <a
              href="#" // FIXME
              style={{
                textDecoration: 'underline',
                color: 'orange',
              }}
            >
              {t('resend_btn')}
            </a>
          </Form.Text>
        )}
      </Form.Group>
      {currentNav && (
        <Button
          variant="primary"
          type="submit"
          disabled={(invalidUsername && submitOnce) || !profile.email}
          onClick={(e) => onSubmit(e, invalidUsername || invalidCurrentPassword, 'EMAIL')}
        >
          {t('save_btn')}
        </Button>
      )}
    </>
  );

  return (
    <Container style={{ padding: '25px' }}>
      <h2>{t('profile_title')}</h2>

      {profileState.loading && (
        <Spinner animation="border" style={{ display: 'block', margin: 'auto' }} />
      )}

      {/* FIXME make it a generic component */}
      <Toast
        style={{
          position: 'absolute',
          top: 5,
          right: 5,
        }}
        onClose={() => setToastContent(null)}
        show={!!toastContent}
        delay={2000}
        autohide
      >
        <Toast.Header>
          <strong className="mr-auto">{t('VOUCHER_SUCCESS')}</strong>
          {/*<small>11 mins ago</small>*/}
        </Toast.Header>
        <Toast.Body>{t(toastContent as TKeys)}</Toast.Body>
      </Toast>

      {!profileState.loading && (
        <>
          <Row
            style={{
              marginTop: 25,
              marginLeft: 5,
              marginRight: 5,
              padding: 15,
              borderStyle: 'solid',
              borderWidth: 2,
              borderColor: 'lightgrey',
              flexDirection: 'column',
            }}
          >
            {/*TODO*/}
            <Link to={routes.subs.path} style={{ textDecoration: 'underline' }}>
              <h5>
                {t('current_sub')} {/* @ts-ignore */}
                {t(subscription?.subscriptionType || SubsType.TASTE)}
              </h5>
            </Link>

            {/* TODO */}
            {/* @deprecated voucher sub activation */}
            {/*{subscription?.subscriptionType === SubsType.TASTE && (*/}
            {/*  <Link*/}
            {/*    to={routes.voucher.path}*/}
            {/*    style={{textDecoration: 'underline'}}>*/}
            {/*    {t('voucher_title')}*/}
            {/*  </Link>*/}
            {/*)}*/}

            {/*TODO */}
            {subscription?.subscriptionType === SubsType.TASTE && (
              <Link to={routes.subs.path} style={{ textDecoration: 'underline' }}>
                {t('sub_upgrade_title')}
              </Link>
            )}
          </Row>
          <Row style={{ marginTop: '25px' }}>
            <Col
              style={{
                borderWidth: '0px 1px 0px 0px',
                borderColor: 'lightgrey',
                borderStyle: 'solid',
                marginLeft: '5px',
                marginRight: '5px',
              }}
            >
              <Nav
                variant="pills"
                activeKey={currentNav ?? ''}
                className="flex-column"
                onSelect={handleSelect}
              >
                {navEntries.map((navEntry: string) => (
                  <Nav.Link
                    style={{
                      borderWidth: '1px 1px 1px 1px',
                      borderColor: 'lightgrey',
                      borderStyle: 'solid',
                      marginTop: '5px',
                      marginBottom: '5px',
                    }}
                    key={navEntry}
                    eventKey={navEntry}
                  >
                    {t(navEntry as TKeys)}
                    <ChevronRight className="float-right" />
                  </Nav.Link>
                ))}
              </Nav>
            </Col>
            <Col>
              <Form>
                {passwordProtectedField && (
                  <Form.Group controlId="formBasicPassword">
                    <Form.Label>{t('psw_current_label')}</Form.Label>
                    <Form.Control
                      type="password"
                      isInvalid={submitOnce && invalidCurrentPassword}
                      placeholder={t('psw_pl')}
                      value={profile.password}
                      onChange={(e) => {
                        const newValue = e.currentTarget.value;
                        setProfile((p) => ({
                          ...p,
                          password: newValue,
                        }));
                      }}
                    />
                    <Form.Control.Feedback type="invalid" style={{ color: 'red' }}>
                      {t('val_non_empty')}
                    </Form.Control.Feedback>
                  </Form.Group>
                )}
                {NavEntry.PASSWORD === currentNav && renderedPassword}
                {NavEntry.FIRSTNAME === currentNav && renderedFirstname}
                {NavEntry.LASTNAME === currentNav && renderedLastname}
                {/* {NavEntry.PHONE === currentNav && renderedPhone2FA} */}
                {NavEntry.EMAIL === currentNav && renderedEmail}
                {!currentNav && <i>{t('profile_empty')}</i>}

                {/*  FIXME check positioning */}
                {displayServerError && (
                  <Form.Text style={{ color: 'red' }}>
                    {t(profileState?.serverError?.errorKey as TKeys)}
                  </Form.Text>
                )}
              </Form>
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
};
