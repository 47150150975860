import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../stores/store';
import {
  APIErrorClient,
  authApi,
  handleNetworkError,
  initialRequestStatus,
  installBearerAuthHeader,
  RequestStatus,
  UNAUTHORIZED_STATUS,
  UNKNOWN_ERROR_KEY,
} from '../../api';
import { AuthUserDTOIn, SubLogin, SubsType } from '../types';
import { burnVoucher } from '../voucherSlice';
import { firebaseSignIn, firebaseSignOut, NO_ID_TOKEN } from '../../api/firebase';

// Define a type for the slice state
interface AuthState extends RequestStatus {
  loggedIn: boolean;
  userId: number;
  email: string;
  emailConfirmed?: boolean;
  lastLoginDate?: Date;
  subscription?: SubLogin;
}

// Define the initial state using that type
const initialState: AuthState = {
  loggedIn: false,
  userId: -1,
  email: '',
  ...initialRequestStatus,
};

export type LoginArgs = {
  email?: string;
  password?: string;
};

const onIdTokenRefresh = (idToken: string) => {
  console.info('onTokenRefresh', { idToken });
  installBearerAuthHeader(authApi, 'authApi', idToken);
};

export const loginAsync = createAsyncThunk<
  AuthUserDTOIn, // output
  LoginArgs, // input
  { rejectValue: APIErrorClient } // error output
>('auth/login', async (args, thunkAPI) => {
  const { email, password } = args;
  console.info('loginAsync', { email, password });
  try {
    authApi.interceptors.request.clear(); // FIXME
    const firebaseUser = await firebaseSignIn(onIdTokenRefresh, email, password);
    const idToken = firebaseUser?.idToken;
    if (!idToken) {
      return thunkAPI.rejectWithValue({
        errorKey: NO_ID_TOKEN,
        submittedData: args,
      } as APIErrorClient);
    }
    let userDTO = (
      await authApi.get(`authorization`, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      })
    )?.data;

    return {
      ...userDTO,
      emailConfirmed: true, //TODO
      // emailConfirmed: firebaseUser?.emailVerified,
      lastLoginDate: new Date().toJSON(),
    } as AuthUserDTOIn;
  } catch (e) {
    return thunkAPI.rejectWithValue(
      handleNetworkError('loginAsync', e, args, [UNAUTHORIZED_STATUS]) as APIErrorClient
    );
  }
});

export const logoutAsync = createAsyncThunk<void, void>('auth/logout', async () => {
  console.info('logoutAsync');
  await firebaseSignOut();
});

export const signinSlice = createSlice({
  name: 'auth',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    reconfirmEmail: (state) => {
      state.emailConfirmed = false; // FIXME password reset
    },
  },
  extraReducers: (bd) => {
    // Add reducers for additional action types here, and handle loading state as needed
    // builder form to keep type checking
    bd.addCase(loginAsync.pending, (state, _) => {
      state.loading = true;
      state.serverError = undefined;
    });
    bd.addCase(logoutAsync.pending, (state, _) => {
      state.loading = true;
      state.serverError = undefined;
    });
    bd.addCase(loginAsync.fulfilled, (state, action) => {
      console.log('fulfilled', { state, action });
      if (action.payload) {
        state.loading = false;
        state.loggedIn = true;
        state.serverError = undefined;
        state.userId = action.payload.id;
        state.email = action.payload.email;
        state.emailConfirmed = action.payload.emailConfirmed;
        // @ts-ignore
        state.subscription = action.payload.subscription;
        state.lastLoginDate = action.payload.lastLoginDate;
      }
    });
    bd.addCase(logoutAsync.fulfilled, (state, _) => {
      state.loggedIn = false;
      state.serverError = undefined;
      state.loading = false;
    });
    bd.addCase(burnVoucher.fulfilled, (state, action) => {
      if (action?.payload?.promotionType === 'PREMIUM_SUBSCRIPTION') {
        state.subscription = {
          startDate: new Date().toISOString(), // FIXME from server
          status: 'ACTIVE',
          subscriptionType: SubsType.DISCOVERY,
          // subscriptionName: {
          //   en: 'Discovery',
          // },
        };
      }
    });
    bd.addCase(loginAsync.rejected, (state, action) => {
      console.log('rejected', { state, action });
      if (action.payload) {
        state.loggedIn = false;
        state.loading = false;
        state.serverError = action.payload;
      } else {
        state.loggedIn = false;
        state.loading = false;
        state.serverError = {
          errorKey: UNKNOWN_ERROR_KEY,
          submittedData: {}, // FIXME
        };
      }
    });
  },
});

// export all actions
export const { reconfirmEmail } = signinSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectLoggedIn = (state: RootState) => state.auth.loggedIn;
