import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxTKHooks';
import { signupAsync } from '../../redux/signupSlice';
import { NewUser } from '../../redux/types';
import { EMAIL_REGEX, ONE_DIGIT, ONE_LOWERCASE, ONE_UPPERCASE } from '../../utils/validation';
import { loadScriptByURL, removeElemByClass, removeScriptById } from '../../utils';
import _ from 'lodash';
import { TKeys } from '../../i18n/en';
import { useTranslation } from '../../hooks/useTranslation';
import logo from '../../assets/ionnyk_logo_black.png';
import { localConfig } from '../../api';
import { __DEV__ } from '../../api';
import { Footer } from '../../components/Footer';

interface Props {
  toggleAuth?: () => void;
}

export const Signup = ({ toggleAuth }: Props) => {
  const t = useTranslation();
  const dispatch = useAppDispatch();
  const signupState = useAppSelector((state) => state.signup);

  useEffect(() => {
    // load the script by passing the URL
    localConfig.SITE_KEY &&
      !__DEV__ &&
      loadScriptByURL(
        'recaptcha-key',
        `https://www.google.com/recaptcha/api.js?render=${localConfig.SITE_KEY}`,
        function () {
          console.log('Script loaded!');
        }
      );
    return () => {
      if (!__DEV__) {
        // cleaning the script and innerHtml created FIXME cleaner way ?
        removeScriptById('recaptcha-key');
        removeElemByClass('grecaptcha-badge');
      }
    };
  }, []);
  // FIXME basic form
  const [submitOnce, setSubmitOnce] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [passwordCheck, setPasswordCheck] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');

  // FIXME better validation
  const invalidUsername = _.isEmpty(email) || !EMAIL_REGEX.test(email);
  const invalidPassword =
    passwordCheck !== password ||
    _.isEmpty(password) ||
    password.length < 6 ||
    password.length > 32 ||
    !ONE_DIGIT.test(password) ||
    !ONE_LOWERCASE.test(password) ||
    !ONE_UPPERCASE.test(password);
  const invalidFirstName = _.isEmpty(firstName);
  const invalidLastName = _.isEmpty(lastName);

  const validationRequired =
    invalidUsername || invalidPassword || invalidFirstName || invalidLastName;

  // server validation
  const newUserData = {
    firstName,
    lastName,
    email,
    password,
  } as NewUser;
  const displayServerError =
    !!signupState.serverError &&
    _.isEqual(newUserData, _.omit(signupState.serverError.submittedData, 'captcha'));

  const onSubmit = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    // captcha
    // @ts-ignore
    if (localConfig.SITE_KEY && !__DEV__) {
      (window as any)?.grecaptcha?.ready(() => {
        // @ts-ignore
        window?.grecaptcha
          .execute(localConfig.SITE_KEY, { action: 'submit' })
          .then((captcha: any) => {
            if (validationRequired) {
              setSubmitOnce(true);
            } else {
              console.log('signupAsync from grecaptcha');
              setSubmitOnce(false);
              dispatch(signupAsync({ ...newUserData, captcha }));
            }
          });
      });
    } else {
      console.debug('no site key catpch');
      if (validationRequired) {
        setSubmitOnce(true);
      } else {
        setSubmitOnce(false);
        // TODO captcha default
        dispatch(signupAsync({ ...newUserData, captcha: '42' }));
      }
    }
  };

  return (
    <Container style={{ padding: '25px' }}>
      <Row style={{ marginBottom: '5rem' }}>
        <Col>
          <img
            style={{
              // image css centering :
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
            src={logo}
            height="30rem"
            alt="Ionnyk logo"
          />
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 6, offset: 3 }}>
          <h3>{t('SignUp')}</h3>
          <Form>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>{t('email_label')}</Form.Label>
              <Form.Control
                isInvalid={submitOnce && invalidUsername}
                type="email"
                placeholder={t('email_pl')}
                value={email}
                onChange={(e) => setEmail(e.currentTarget.value)}
              />
              <Form.Control.Feedback type="invalid" style={{ color: 'red' }}>
                {t('val_misc_format')}
              </Form.Control.Feedback>
              <Form.Text className="text-muted">{t('email_hint')}</Form.Text>
            </Form.Group>

            <Form.Group controlId="formBasicEmail">
              <Form.Label>{t('firstname_label')}</Form.Label>
              <Form.Control
                isInvalid={submitOnce && invalidFirstName}
                placeholder={t('firstname_pl')}
                value={firstName}
                onChange={(e) => setFirstName(e.currentTarget.value)}
              />
              <Form.Control.Feedback type="invalid" style={{ color: 'red' }}>
                {t('val_non_empty')}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>{t('lastname_label')}</Form.Label>
              <Form.Control
                isInvalid={submitOnce && invalidLastName}
                placeholder={t('lastname_pl')}
                value={lastName}
                onChange={(e) => setLastName(e.currentTarget.value)}
              />
              <Form.Control.Feedback type="invalid" style={{ color: 'red' }}>
                {t('val_non_empty')}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formBasicPassword">
              <Form.Label>{t('psw_label')}</Form.Label>
              <Form.Control
                isInvalid={submitOnce && invalidPassword}
                type="password"
                autoComplete="new-password"
                placeholder={t('psw_pl')}
                value={password}
                onChange={(e) => setPassword(e.currentTarget.value)}
              />
              <Form.Text className="text-muted">{t('psw_hint')}</Form.Text>
            </Form.Group>
            <Form.Group controlId="formBasicPassword">
              <Form.Label>{t('psw_repeat_label')}</Form.Label>
              <Form.Control
                isInvalid={submitOnce && invalidPassword}
                type="password"
                autoComplete="new-password"
                placeholder={t('psw_pl')}
                value={passwordCheck}
                onChange={(e) => setPasswordCheck(e.currentTarget.value)}
              />
              <Form.Control.Feedback type="invalid" style={{ color: 'red' }}>
                {t('val_psw_strength')}
              </Form.Control.Feedback>
            </Form.Group>
            <a
              className="text-muted"
              style={{ textDecoration: 'underline', fontStyle: 'italic' }}
              href={toggleAuth ? '#' : '/signup'}
              onClick={toggleAuth && toggleAuth}
            >
              {t('gobacklogin_btn')}
            </a>
            {displayServerError && (
              <Form.Text style={{ color: 'red' }}>
                {t(signupState?.serverError?.errorKey as TKeys)}
              </Form.Text>
            )}
            <br />
            <br />

            <Button
              variant="primary"
              type="submit"
              disabled={validationRequired && submitOnce}
              onClick={onSubmit}
            >
              {t('register_btn')}
            </Button>
          </Form>
        </Col>
      </Row>
      <Row className="mt-3">
        <Footer />
      </Row>
    </Container>
  );
};
