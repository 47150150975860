import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useTranslation } from '../../hooks/useTranslation';
import logo from '../../assets/ionnyk_logo_black.png';
import { UNKNOWN_ERROR_KEY } from '../../api';
import { ExitProps } from '../../components/ProtectedRoute/AuthProtector';
import _ from 'lodash';
import { EMAIL_REGEX } from '../../utils/validation';
import { apiForgotPsw } from '../../api/resetPasswordApi';
interface Props {}

export const PublicForgotPsw = ({ exitRouteFn }: Props & ExitProps) => {
  useEffect(() => {
    console.log('PublicForgotPsw mount');
  }, []);

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState<string>('');
  const [submitOnce, setSubmitOnce] = useState<boolean>(false);
  const [serverError, setServerError] = useState<any>(null);
  const displayServerError =
    !!serverError &&
    (serverError.errorKey === UNKNOWN_ERROR_KEY || // always display it
      _.isEqual(email, serverError.submittedData));

  const invalidEmail =
    // @ts-ignore
    _.isEmpty(email) || !EMAIL_REGEX.test(email);

  const submit = () => {
    // console.log('submit', email);
    setSubmitOnce(true);
    setServerError(undefined);
    if (invalidEmail) {
      return;
    }
    setLoading(true);
    apiForgotPsw({ email })
      .then(() => {
        alert(t('email_to_send'));
        setTimeout(() => exitRouteFn && exitRouteFn(), 750);
      })
      .catch((e) => {
        console.warn('apiForgotPsw', e);
        setServerError(e ? e : { errorKey: UNKNOWN_ERROR_KEY, submittedData: email });
      })
      .finally(() => setLoading(false));
  };

  const t = useTranslation();
  return (
    <Container style={{ padding: '25px' }}>
      <Row style={{ marginBottom: '5rem' }}>
        <Col>
          <img
            style={{
              // image centering :
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
            src={logo}
            height="30rem"
            alt="Ionnyk logo"
          />
        </Col>
      </Row>
      {/*<Debug v={serverError}/>*/}
      <Row>
        <Col md={{ span: 6, offset: 3 }}>
          <h3>{t('forgot_psw_title')}</h3>
          <Form>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>{t('email_label')}</Form.Label>
              <Form.Control
                isInvalid={submitOnce && invalidEmail}
                type="email"
                placeholder={t('email_pl')}
                value={email}
                onChange={(e) => setEmail(e.currentTarget.value)}
              />
              <Form.Control.Feedback type="invalid" style={{ color: 'red' }}>
                {t('val_misc_format')}
              </Form.Control.Feedback>
            </Form.Group>
            <Button
              disabled={(invalidEmail && submitOnce) || loading}
              style={{ width: '100%' }}
              variant="primary"
              type="submit"
              onClick={(e) => {
                e.preventDefault(); // stop submit form
                e.stopPropagation();
                submit();
              }}
            >
              {loading ? t('page_loading') : t('submit_btn')}
            </Button>
            {!!displayServerError && (
              <Form.Text style={{ color: 'red' }}>{t(serverError.errorKey)}</Form.Text>
            )}
            <hr style={{ color: 'black', width: '100%' }} />
            <a
              className="text-muted"
              style={{ textDecoration: 'underline', fontStyle: 'italic' }}
              href={'/signin'}
            >
              {t('gobacklogin_btn')}
            </a>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};
