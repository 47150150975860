import { CLIENT_BAD_REQUEST_STATUS_STRING, UNKNOWN_ERROR_KEY } from '../api';
/*
 cf. useTranslation
 */
export const en = {
  // NAVS
  Home: 'Home',
  Voucher: 'Voucher',
  Profile: 'Profile',
  Subs: 'Subscriptions',
  Login: 'Login',
  SignUp: 'Sign Up',
  // SCREENS
  onboarding_title: 'Welcome to your Ionnyk account !',
  onboarding_s1_title: '1. Validate your email address',
  onboarding_s1_body:
    'Please open your mail client, check the spam folder if needed, and click on the provided link. Mail not received ?',
  onboarding_s2_title: '2. Redeem your voucher (if any)',
  onboarding_s2_body:
    'If you received a voucher with your Ionnyk frame and you want to activate your digital assets.',
  onboarding_s3_title: '3. Install and login in the Ionnyk app !',
  onboarding_s3_subtitle: 'Use the Ionnyk app to manage your frame',
  onboarding_s3_body:
    'Browse the gallery, create playlists, change pictures to suit settings and moods or buy your favorite artwork ...',
  profile_title: 'Your profile',
  profile_empty: 'Select a profile property',
  voucher_title: 'Redeem a voucher', // BUTTONS
  sub_upgrade_title: 'Upgrade your plan', // BUTTONS
  subs_title: 'Manage your subscription',
  signin_btn: 'Sign In',
  signup_btn: 'Sign Up',
  save_btn: 'Save',
  register_btn: 'Register',
  apply_btn: 'Apply',
  use_voucher_btn: 'USE VOUCHER',
  logout_btn: 'Logout',
  resend_btn: 'Resend Link',
  skip_btn: 'SKIP',
  close_btn: 'Close',
  gobacklogin_btn: 'Already a user ? Please login here',
  forgotpsw_btn: 'I forgot my password',
  contact_us: 'Technical issue ?',
  email_to_confirm: 'This email address has not yet been confirmed. Please open received link.',
  // FORMS
  email_pl: 'Enter email',
  email_hint: "We'll never share your email with anyone else.",
  email_label: 'Email address',
  psw_pl: 'Password',
  psw_label: 'Password',
  psw_repeat_label: 'Repeat Password',
  psw_current_label: 'Current Password',
  psw_hint:
    'At least one uppercased letter, one lowercased letter, one digit and between 6 and 32 characters',
  firstname_pl: 'Firstname',
  firstname_label: 'Firstname',
  lastname_pl: 'Lastname',
  lastname_label: 'Lastname',
  phone2fa_pl: 'Phone (2FA)',
  phone2fa_label: 'Phone (2FA)',
  invalid_login: 'Invalid email and/or password. Please retry',
  voucher_hint: 'Please note it can be only used once.',
  voucher_label: 'Please enter your voucher',
  // FORM validation
  val_misc_format: 'Please check that the format is correct',
  val_non_empty: 'Must not be empty',
  val_psw_strength:
    'Please check that the passwords match. Should at least contain one uppercase letter, one lowercase letter, one digit and between 6 and 32 characters',
  // General API Error
  [CLIENT_BAD_REQUEST_STATUS_STRING]:
    'Please verify the provided data (missing field, too long or too short or wrong format)',
  // Voucher api
  VOUCHER_SUCCESS: 'Success',
  PREMIUM_SUBSCRIPTION: 'Premium subscription has been successfully activated !',
  VOUCHER_NOT_FOUND: 'Voucher not found',
  VOUCHER_EXPIRED: 'Voucher is not usable anymore (expired)',
  VOUCHER_ALREADY_USED: 'Voucher has already been redeemed',
  PROMOTION_NOT_APPLICABLE: 'The promotion is not applicable',
  // Signup - Profile api errors
  EMAIL_ALREADY_USED: 'An account already exists for this email. Please login instead',
  WEAK_PASSWORD: 'The provided password is not strong enough',
  AUTH_FAILED: "The given password doesn't match with the current password",
  INVALID_CAPTCHA: 'Invalid captcha, please retry later',
  [UNKNOWN_ERROR_KEY]: 'Unknown server error, please retry later',
  // Profile api
  profile_updated: 'The profile has been successfully updated.',
  // Public
  page_loading: 'Loading ...',
  pay_cancel_title: 'Your payment has been cancelled',
  pay_cancel_subtitle: "Order canceled -- continue to shop around and checkout when you're ready.",
  pay_success_title: 'Congratulations !',
  pay_success_subtitle:
    'Your payment is successful. ' +
    'You WILL SOON find and display on your IONNYK frame your new Artwork from your private Vault. It may take a few minutes',
  forgot_psw_title: 'Password forgotten',
  reset_psw_title: 'New password',
  submit_btn: 'Submit',
  email_to_send: 'An email will be sent',
  invalid_link: 'The link is invalid',
  expired_link: 'The link is expired',
  psw_bad_format: 'The password is not valid (strength). Please retry',
  psw_unk_error: 'Password change is not successful. Please retry later',
  no_token: 'Token not found',
  success_reset: 'Password change is successful. You will be redirected to login',
  current_sub: 'Active plan: ',
  freemium: '"Taste of Ionnyk"',
  premium: '"Discovery"',
};

export type TKeys = keyof typeof en;
